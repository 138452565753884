import { render, staticRenderFns } from "./DetailForm.vue?vue&type=template&id=6a66c092&scoped=true"
import script from "./DetailForm.vue?vue&type=script&lang=js"
export * from "./DetailForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a66c092",
  null
  
)

export default component.exports