<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog v-model="dialogFeedback" max-width="1400px" scrollable persistent>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form_feedback === 'add'">Add Feedback Detail</span>
              <span class="headline" v-else>Edit Feedback Detail</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogFeedback = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Chọn kỹ năng</label></v-col>
                    <v-col cols="8" class="p-0 m-0 ml-4"><v-autocomplete :items="skill_tags" item-text="name" item-value="id" v-model="skill_input" filled color="blue-grey lighten-2" dense></v-autocomplete></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Nhập trình độ</label></v-col>
                    <v-col cols="8" class="p-0 m-0 ml-4"><v-text-field dense filled v-model="display_level_input"></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Nhập band điểm</label></v-col>
                    <v-col cols="1" class="p-0 m-0 ml-4"><v-text-field type="number" dense filled v-model="score_start_input"></v-text-field></v-col>
                    <v-icon class="ml-6 mr-6 mb-6">mdi-arrow-right-bold-outline</v-icon>
                    <v-col cols="1" class="p-0 m-0"><v-text-field type="number" dense filled v-model="score_end_input"></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Số câu đúng</label></v-col>
                    <v-col cols="1" class="p-0 m-0 ml-4"><v-text-field type="number" dense filled v-model="range_start_input"></v-text-field></v-col>
                    <v-icon class="ml-6 mr-6 mb-6">mdi-arrow-right-bold-outline</v-icon>
                    <v-col cols="1" class="p-0 m-0"><v-text-field type="number" dense filled v-model="range_end_input"></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Nhập lời khuyên</label></v-col>
                    <v-col cols="8" class="p-0 m-0 ml-4"><Editor v-model="advice_input"></Editor></v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"><label class="text-h6">Nhập nhận xét</label></v-col>
                    <v-col cols="8" class="ml-4 p-0">
                      <v-card>
                        <v-tabs
                            v-model="tab"
                            background-color="#f0f0f0"
                            dark
                        >
                          <v-tab
                              v-for="(item_comment, iItem) in data_comment"
                              :key="iItem"
                              :style="{color: (item_comment.type === 1) ? '#00E676' : 'red'}"
                          >
                            <span class="font-weight-bold">{{ item_comment.title }}</span>
                            <v-icon class="d-block ml-1" color="#424242" @click="editDataComment(iItem)">mdi-file-document-edit-outline</v-icon>
                            <v-icon class="d-block" color="#424242" @click="deleteDataComment(iItem)">mdi-close</v-icon>
                          </v-tab>
                          <v-tabs-slider color="#8C9EFF"></v-tabs-slider>
                          <v-icon color="cyan" id="iconAddDataComment" @click="openDialogDataComment">mdi-plus</v-icon>
                        </v-tabs>

                        <v-tabs-items vertical v-if="data_comment.length > 0" v-model="tab"  style="min-height: 200px">
                          <v-tab-item
                              v-for="(item_comment, iItem) in data_comment"
                              :key="iItem"
                          >
                            <v-card flat>
                              <v-row>
                                  <v-textarea v-model="comment_input" outlined auto-grow dense rows="1" class="ml-8 p-0 col-7 mt-8 text-body-1"></v-textarea>
                                  <v-btn color="cyan" class="ma-2 mt-8 font-weight-bold" outlined @click="btnAddComment(iItem)">
                                    <v-icon small left>mdi-check</v-icon> Lưu
                                  </v-btn>
                                  <v-btn color="#E53935" class="mt-8 font-weight-bold" outlined @click="resetAddComment">
                                    <v-icon small left>mdi-close</v-icon> Xóa nội dung
                                  </v-btn>
                              </v-row>
                              <ul>
                                <template v-for="(comment, iCmt) in item_comment.comments">
                                  <li class="d-flex mt-4" :key="iCmt">
                                    <v-btn color="#FFAB00" outlined @click="editComment(iItem, iCmt)" small>
                                      <v-icon small>mdi-file-document-edit-outline</v-icon>
                                    </v-btn>
                                    <v-btn color="#D84315" class="ml-1" outlined @click="deleteComment(iItem, iCmt)" small>
                                      <v-icon small>mdi-close</v-icon>
                                    </v-btn>
  <!--                                  <v-icon class="d-block" color="#FFAB00" @click="editComment(iItem, iCmt)">mdi-file-document-edit-outline</v-icon>-->
  <!--                                  <v-icon class="d-block" color="#D84315" @click="deleteComment(iItem, iCmt)">mdi-close</v-icon>-->
                                    <p class="text-body-1 mb-0 ml-2 mr-2 text-justify">{{comment}}</p>
                                  </li>
                                </template>
                              </ul>
                            </v-card>
                          </v-tab-item>
                        </v-tabs-items>
                        <v-tabs-items vertical v-model="tab" v-if="data_comment.length <= 0" style="min-height: 200px">
                          <p style="text-align: center; margin-top: 80px">No data</p>
                        </v-tabs-items>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button class="btn btn-light-success text-uppercase mr-2" v-if="type_form_feedback === 'add'" @click="btnAddOrEditFeedbackDetail(1)">Add</button>
            <button class="btn btn-light-success text-uppercase mr-2" v-if="type_form_feedback === 'update'" @click="btnAddOrEditFeedbackDetail(2)">Update</button>
            <button class="btn text-uppercase mr-2" style="color:#f64e60" @click="dialogFeedback = false">Close</button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogTitleComment" max-width="800px" scrollable persistent>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form_data_comment === 'add'">Thêm tiêu đề nhận xét</span>
              <span class="headline" v-else>Sửa tiêu đề nhận xét</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogTitleComment = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="7" class="pt-0">
                  <v-text-field label="Tiêu đề" v-model="title_comment_input" required></v-text-field>
                </v-col>
                <v-col cols="6" class="d-flex pb-0">
                  <label style="font-size: 16px" class="mt-1 p-0">Type: </label>
                  <v-radio-group row class="mt-0 ml-2" v-model="type_comment_input">
                    <v-radio label="Positive (màu xanh)" :value="1"></v-radio>
                    <v-radio label="Negative (màu đỏ)" :value="2"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button class="btn btn-light-success text-uppercase mr-2" @click="btnSaveOrEditDataComment">Save</button>
            <button class="btn text-uppercase mr-2" style="color:#F64E60" @click="dialogTitleComment = false">Close</button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
import Editor from "@/components/ckeditor5/Editor";
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "DetailForm",
  components: {
    Editor,
    Loading: () => import('vue-loading-overlay'),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false
    },
    type_form_feedback: {
      type: String,
      default: ''
    },
    feedback_detail: {},
  },
  data() {
    return {
      is_call_api: false,
      feedback_detail_id: null,
      skill_input: null,
      skill_tags: [],
      score_start_input: null,
      score_end_input: null,
      advice_input: null,
      display_level_input: '',
      tab: null,
      dialogTitleComment: false,
      title_comment_input: '',
      type_comment_input: 1,
      type_form_data_comment: '',
      type_form_comment: '',
      indexDataComment: null,
      indexComment: null,
      comment_input: '',
      data_comment: [],
      range_start_input: null,
      range_end_input: null,
    }
  },
  computed: {
    result_analysis_id: function () {
      return this.$route.params.id;
    },
    dialogFeedback: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit('setDialog', val);
      }
    }
  },
  watch: {
    comment_input(val) {
      if (val === '') {
        this.type_form_comment = '';
        this.indexComment = null;
      }
    },
    dialogFeedback(val) {
      if (val) {
        this.setDataForm();
      }
    }
  },
  created() {
    this.getAllSkill();
  },
  methods: {
    setDataForm() {
      if(this.type_form_feedback === 'update') {
        let data = this.feedback_detail;
        this.skill_input = data.skill.id;
        this.score_start_input = data.score_start;
        this.score_end_input = data.score_end;
        this.advice_input = (data.advice == null) ? '' : data.advice;
        this.display_level_input = data.display_level;
        let data_comment_json = JSON.stringify(data.data_comment);
        this.data_comment = JSON.parse(data_comment_json);
        this.feedback_detail_id = data.id;
        this.range_start_input = data.range_start;
        this.range_end_input = data.range_end;
      } else {
        this.skill_input = null;
        this.score_start_input = null;
        this.score_end_input = null;
        this.advice_input = '';
        this.display_level_input = '';
        this.data_comment = [];
        this.range_start_input = null;
        this.range_end_input = null;
      }
      this.resetAddComment();
    },
    async getAllSkill() {
      let vm = this;
      await ApiService.get('prep-app/tag/list-skill')
          .then(function (res) {
            if(res.status === 200) {
              vm.skill_tags = res.data;
            }
          })
    },
    openDialogDataComment() {
      this.type_comment_input = 1;
      this.title_comment_input = '';
      this.type_form_data_comment = 'add';
      this.indexDataComment = null;
      this.dialogTitleComment = true;
    },
    btnSaveOrEditDataComment() {
      if (this.title_comment_input === null || this.title_comment_input === '') {
        this.$toasted.error('Tiêu đề không được bỏ trống !', {theme: "toasted-primary", position: "top-right", duration : 4000});
        return;
      }
      if (this.type_form_data_comment === 'add') {
        let data = {
          title: this.title_comment_input,
          type: this.type_comment_input,
          comments: [],
        }
        this.tab = this.data_comment.push(data) -1;
      } else {
        this.data_comment[this.indexDataComment].title = this.title_comment_input;
        this.data_comment[this.indexDataComment].type = this.type_comment_input;
      }
      this.dialogTitleComment = false;
    },
    editDataComment(index) {
      let data = this.data_comment[index];
      this.indexDataComment = index;
      this.title_comment_input = data.title;
      this.type_comment_input = data.type;
      this.type_form_data_comment = 'update';
      this.dialogTitleComment = true;
    },
    deleteDataComment(index) {
      let vm = this;
      Swal.fire({
        title: 'Are you sure you want to delete?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          vm.data_comment.splice(index, 1);
        }
      })
    },
    btnAddComment(index) {
      if (this.comment_input === null || this.comment_input === '') {
        this.$toasted.error('Nhận xét bỏ trống !', {theme: "toasted-primary", position: "top-right", duration : 4000});
        return;
      }
      if (this.type_form_comment === 'update') {
        this.data_comment[index].comments[this.indexComment] = this.comment_input;
      } else {
        this.data_comment[index].comments.push(this.comment_input);
      }
      this.type_form_comment = null;
      this.comment_input = '';
    },
    editComment(indexDataComment, indexComment) {
      this.indexComment = indexComment;
      this.comment_input = this.data_comment[indexDataComment].comments[indexComment];
      this.type_form_comment = 'update';
    },
    deleteComment(indexDataComment, indexComment) {
      let vm = this;
      Swal.fire({
        title: 'Are you sure you want to delete?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          vm.data_comment[indexDataComment].comments.splice(indexComment, 1);
        }
      })
    },
    resetAddComment() {
      this.type_form_comment = '';
      this.indexComment = null;
      this.comment_input = '';
    },
    btnAddOrEditFeedbackDetail(type) {
      let vm = this;
      if (this.data_comment.length <= 0) {
        this.$toasted.error('Bạn chưa nhập dữ liệu nhận xét !', {theme: "toasted-primary", position: "top-right", duration : 4000});
        return;
      }
      let data = {
        skill_id: this.skill_input,
        score_start: this.score_start_input,
        score_end: this.score_end_input,
        display_level: this.display_level_input,
        data_comment: this.data_comment,
        type: 2
      }
      if (this.advice_input) data.advice = this.advice_input;
      if (this.range_start_input != null && this.range_start_input !== '') {
        data.range_start = this.range_start_input;
      }
      if (this.range_end_input != null && this.range_end_input !== '') {
        data.range_end = this.range_end_input;
      }
      if (type === 1) {
        vm.is_call_api = true;
        ApiService.post('prep-app/test-result-analysis/'+this.result_analysis_id+'/feedback', data)
        .then(function (res) {
          vm.$emit('reloadDetailFeebackList');
          if (res.status === 200) {
            Swal.fire({
              title: "",
              text: "The application has been successfully submitted!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            });
            vm.is_call_api = false;
            vm.dialogFeedback = false;
          }
        })
        .catch(function (error) {
          vm.is_call_api = false;
          if(error.response.status === 422) {
            let data_response_error = error.response.data.error.message.split('.<br />');
            vm.showError(data_response_error);
          }
        });
      } else {
        vm.is_call_api = true;
        ApiService.put('prep-app/test-result-analysis/'+this.result_analysis_id+'/feedback/'+this.feedback_detail_id, data)
          .then(function (res) {
            vm.$emit('reloadDetailFeebackList');
            if (res.status === 202) {
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary"
              });
              vm.is_call_api = false;
              vm.dialogFeedback = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if(error.response.status === 422) {
              let data_response_error = error.response.data.error.message.split('.<br />');
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i]+' !!', {
          theme: "toasted-primary",
          position: "top-right",
          duration : 4000
        })
      }
    },
  },

}
</script>

<style scoped>
ul, ol {
  list-style: none;
}
#iconAddDataComment {
  max-width: 200
