<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Chi tiết phản hồi {{ result_analysis_title }}
      </h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDialogDetailFeedback"
        v-if="canAccess('entry-test-create')"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm Feedback detail</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Kỹ năng</th>
                    <th scope="col">Band điểm</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(detail, i) in feedback_details">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ detail.skill.name }}
                        </p>
                      </td>
                      <td>
                        <p class="mb-0">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >{{ detail.score_start }}</span
                          >
                          <v-icon class="ml-2"
                            >mdi-arrow-right-bold-outline</v-icon
                          >
                          <span
                            class="ml-2 text-dark-75 font-weight-bolder font-size-lg"
                            >{{ detail.score_end }}</span
                          >
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-warning btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="editDetailFeedback(i)"
                                v-if="canAccess('entry-test-update')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteDetailFeedback(detail.id)"
                                v-if="canAccess('entry-test-delete')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!feedback_details.length">
                    <tr>
                      <td style="text-align: center" colspan="6">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <DetailForm
      @reloadDetailFeebackList="getDataDetail"
      :type_form_feedback="type_form_feedback"
      :show_dialog="dialogFeedback"
      :feedback_detail="feedback_detail"
      @setDialog="(e) => (dialogFeedback = e)"
    />
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import DetailForm from "./modal/DetailForm.vue";
import Swal from "sweetalert2";
export default {
  name: "DetailFeedback",
  components: {
    Loading: () => import("vue-loading-overlay"),
    DetailForm: DetailForm,
  },
  data() {
    return {
      is_call_api: false,
      feedback_details: [],
      feedback_detail: {},
      result_analysis_title: "",
      dialogFeedback: false,
      type_form_feedback: null,
      skill_tags: [],
      skill_input: null,
    };
  },
  computed: {
    result_analysis_id: function () {
      return this.$route.params.id;
    },
  },
  created() {
    this.getDataDetail();
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    async getDataDetail() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(
          "prep-app/test-result-analysis/" +
            this.result_analysis_id +
            "/feedback/detail"
        );
        if (res.status === 200) {
          vm.feedback_details = res.data.data;
          vm.result_analysis_title = res.data.result_analysis.title;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
      }
    },
    openDialogDetailFeedback() {
      this.type_form_feedback = "add";
      this.dialogFeedback = true;
    },
    editDetailFeedback(index) {
      this.feedback_detail = this.feedback_details[index];
      this.type_form_feedback = "update";
      this.dialogFeedback = true;
    },
    deleteDetailFeedback(feedback_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete(
            "prep-app/test-result-analysis/" +
              this.result_analysis_id +
              "/feedback/" +
              feedback_id
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getDataDetail();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
